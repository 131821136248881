import React, { useState, useRef } from 'react';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import styled from 'styled-components';
import { breakpointDown } from '@paljs/ui/breakpoints';
import { nodeDetails } from '../../actions/nodeDetailsAction';
import { ToastrRef, ToastrProps } from '@paljs/ui/Toastr';
import { changeUserApi } from '../../actions/changeUserAction';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';

const CardAuth = styled(Card)`
  margin-bottom: 0;
  height: calc(100vh - 5rem);
  ${breakpointDown('sm')`
    height: 100vh;
  `}
`;

const ChangeUser = () => {
  const [nodeId, setNodeId] = useState('');
  const [mobile, setMobile] = useState('');
  const [toastData, setToastData] = useState<ToastrProps>({
    position: 'topEnd',
    status: 'Success',
    duration: 2000,
    hasIcon: true,
    destroyByClick: true,
    preventDuplicates: false,
  });
  const toastrRef = useRef<ToastrRef>(null);

  const onSubmit = async () => {
    try {
      if (nodeId) {
        const nodeDetailsResponse = await nodeDetails(nodeId);
        if (nodeDetailsResponse?.basicProfile?.registered_mobile) {
          const newTokenCreated = await changeUserApi({ mobile: nodeDetailsResponse.basicProfile.registered_mobile });
          if (newTokenCreated.status) {
            toast.success(`Logged in user changed successfully ${newTokenCreated.name}`);
            navigate('/family-tree');
          } else {
            toast.error('Token is not generated');
          }
        } else {
          toast.error('Mobile No. is not registered yet for this NodeId');
        }
      } else if (mobile) {
        const newTokenCreated = await changeUserApi({ mobile });
        if (newTokenCreated.status) {
          toast.success(`Logged in user changed successfully ${newTokenCreated.name}`);
          navigate('/family-tree');
        } else {
          toast.error(newTokenCreated?.error_message);
        }
      } else {
        toast.error('Please enter Node ID or Mobile Number');
      }
    } catch (error) {
      console.log('Error in Changing User', error);
      toast.error('Error in Changing User');
    }
  };

  return (
    <div style={{ paddingTop: '150px' }}>
      <Card>
        <CardBody>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <InputGroup fullWidth style={{ width: '300px' }}>
              <input
                type="text"
                placeholder="Enter Node Id"
                onChange={(e) => setNodeId(e.target.value.trim())}
                value={nodeId}
                disabled={mobile.length > 0}
              />
            </InputGroup>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0' }}>
            <span>or</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <InputGroup fullWidth style={{ width: '300px' }}>
              <input
                type="text"
                placeholder="Enter Mobile Number"
                onChange={(e) => setMobile(e.target.value.trim())}
                value={mobile}
                disabled={nodeId.length > 0}
              />
            </InputGroup>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
            <Button status="Success" type="button" shape="SemiRound" onClick={onSubmit}>
              Login
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ChangeUser;
