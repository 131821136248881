import { storeToken } from "./authAction";
import {  postApiWrapper } from "./token-wrapper-function";

export const changeUserApi = async (data) => {
    const url = `/api/web_login`;
    const result = await postApiWrapper(url, data);
    console.log("result from change user", result)
    if (result.status) {
        storeToken(result);
    }
    return result;
}

